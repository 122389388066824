<template>
    <div class="editar-grupo" :class="show ? 'activo':'inactivo'">
        <div class="row header mx-0 py-2">
            <div class="col-12 d-flex ">
                <i class="icon-arrow-left-circle f-25 mr-4 my-auto cr-pointer" @click="$emit('close')" />
                <p class="f-17 f-500 my-auto">Editar grupo</p>
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col-12 text-center">
                <div class="contenedor-img-grupo mx-auto">
                    <slim-cropper ref="cropGrupo" :options="slimOptions" class="border cr-pointer mx-auto" style="height:164px;width:164px;background-color:#f1f1f1; color: #868686;border-radius:50%;" />
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-5 justify-content-center">
            <div class="col-10">
                <p class="f-12 pl-2 label-inputs">Nombre del grupo</p>
                <el-input v-model="form.nombre" placeholder="Nombre grupo" size="small" />
            </div>
            <div class="col-10">
                <p class="f-12 pl-2 label-inputs mt-2">Descripción</p>
                <el-input v-model="form.descripcion" type="textarea" maxlength="300" placeholder="Descripción..." :rows="3" />
            </div>
        </div>
        <div class="row mx-0 mt-4 justify-content-center">
            <div class="col-10">
                <button class="btn btn-general f-14 f-300 w-100" @click="guardarEdicionGrupo">
                    Guardar
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Chats from '~/services/chats/chat-admin'
import {mapGetters} from 'vuex'
export default {
    props:{
        show:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{

            slimOptions: {
                ratio: '1:1',
                label:'Añadir imagen del grupo',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },

            form:{
                nombre:'',
                descripcion:''
            }
        }
    },
    computed:{
        ...mapGetters({
            grupo:'chatsGrupos/grupoActivo'
        })
        
    },
    watch:{
        show(value){
            if(value){
                this.form.nombre =  this.grupo.nombre,
                this.form.descripcion = this.grupo.descripcion

                if(this.grupo.imagen != "/img/no-imagen/noimagenanuncio.svg"){
                    this.$refs.cropGrupo.instanciaCrop.load(this.grupo.imagen)
                }
            }
        }
        
    },
    methods:{
        async guardarEdicionGrupo(){
            try {
              
                this.form.logo = this.$refs.cropGrupo.get_image()

                const {data} = await Chats.editarGrupo(this.grupo.id_grupo, this.form)
                this.notificacion('','Grupo actualizado correctamente','success')
                this.$store.dispatch('chatsGrupos/updateDetalle', this.grupo)
                this.$emit('close')
              
            } catch (error){
                this.errorCatch(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.info-chat-grupo{
    position: relative;
    .wh-164px{
        max-width: 164px;
        min-width: 164px;
        max-height: 164px;
        min-height: 164px;
    }
    .img-grupo{
        @extend .wh-164px;
        border-radius: 50%;
        border: 2px solid #F5F5F5;
    }
    .contenedor-info{
        height: calc(100vh - 115px);
    }
    .div-divisor{
        height: 5px;
        background: #F5F5F5;
    }
    .icon-navigation{
        transform: rotate( 90deg );
    }
    .tipo-user{
        i.icon-buildings{
            &:before{
                margin-left: 0px;
            }
        }
        i.icon-account-outline{
            font-size: 25px;
        }
    }
    @keyframes slidein {
        from {
            width: 0%;
        }

        to {
            width: 100%;
        }
    }

    .editar-grupo, .agregar-residentes, .seleccionar-admins, .envio-mensajes{
        background: #fff;
        height: calc(100vh - 56px);
        position: absolute;
        top: 0;
        width: 103.9%;
        right: -393.77px;
        display: none;
        &.activo{
            transform: translateX(-379px);
            display: block !important;
            animation-duration: 0.35s;
            animation-name: slidein;
        }
        .header{
            background: #f1f1f1;
        }
        .contenedor-img-grupo{
            @extend .wh-164px;
            border-radius: 50%;
            border: 2px solid #F5F5F5;
            position:relative;
            .bg-hover{
                @extend .wh-164px;
                border-radius: 50%;
                background: #000;
                opacity: 0;
                position: absolute;
                transition: .35s;
            }
            .img-grupo{
                border: none !important;
            }
            &:hover{
                .bg-hover{
                    opacity: 0.7;
                    transition: .45s;

                }
            }
        }
        .contenedor-residentes{
            height: calc(100vh - 206px);
        }
        .contenedor-residentes-admins{
            height: calc(100vh - 135px);
        }
        .mas-opciones.opt{
            position: relative;
            .cantidad{
                position: absolute;
                color: white;
                background: #FF4D59;
                border-radius: 7px;
                font-size: 8px;
                top: -6px;
                right: -10px;
                font-weight: 500;
            }
        }
    }
}
</style>